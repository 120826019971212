import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Button, Divider, FormControlLabel, Switch, Typography, IconButton,
  Grid,
} from '@material-ui/core';
import { VisibilityOutlined, ReplyOutlined } from '@material-ui/icons';
import useStyles from './CreateTask/styles';
import {
  LONG_ANSWER,
  SHORT_ANSWER,
  DROP_DOWN_MENU,
  SIMPLE_SELECTION,
  SATISFACTION,
  MULTIPLE_SELECTION,
  IMAGE,
  YES_NO_QUESTION,
  NUMERIC_ANSWER,
  DATE,
  WELCOME_PAGE,
  RESUME_PAGE,
  CONTENT,
  END_PAGE,
  NPS_QUESTION,
  MULTIPLE_IMAGE_QUESTION,
  SIGNATURE_QUESTION,
  BARCODE_QUESTION,
  DECIMAL_ANSWER,
  PRICE,
} from '../../utils/const/questions';

import {
  VIEW_MODE,
} from '../../utils/const/taskModes';

import { VIDEO } from '../../utils/const/pageTypes';

import SimpleSelection from '../../components/Pages/SimpleSelection';
import MultipleSelection from '../../components/Pages/MultipleSelection';
import DropDownMenu from '../../components/Pages/DropDownMenu';
import LongAnswer from '../../components/Pages/LongAnswer';
import ShortAnswer from '../../components/Pages/ShortAnswer';
import Satisfaction from '../../components/Pages/Satisfaction';
import ImageAnswer from '../../components/Pages/ImageAnswer';
import YesNoQuestion from '../../components/Pages/YesNoQuestion';
import NumericAnswer from '../../components/Pages/NumericAnswer';
import DateAnswer from '../../components/Pages/DateAnswer';
import WelcomePage from '../../components/Pages/WelcomePage';
import ResumePage from '../../components/Pages/ResumePage';
import NpsQuestion from '../../components/Pages/NpsQuestion';
import MultipleImageQuestion from '../../components/Pages/MultipleImageQuestion';
import SignatureQuestion from '../../components/Pages/SignatureQuestion';
import BarcodeQuestion from '../../components/Pages/BarcodeQuestion';
import DecimalAnswer from '../../components/Pages/DecimalAnswer';
import Price from '../../components/Pages/Price';
import Video from '../../components/Pages/Video';

import QuestionLogic from '../QuestionLogic';
import CreateTaskModal from './CreateTask';

function TaskDetail({
  questions, title, formType, setTaskId, setQuestions, setTaskInfo,
  setDuplicateTask, setEditTask, defaultOpen, setCurrentOpen, setEditView,
  inputValues, setInputValues, taskInfo, taskId,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [viewDetail, setViewDetail] = useState(null);
  const generalView = false;
  // const [generalView, setGeneralView] = useState(false);
  const [selectedPage, setSelectedPage] = useState(questions);
  const [switchStock, setSwitchStock] = useState(null);

  const [openConnections, setOpenConnections] = useState(false);
  const [pages, setPages] = useState([
    {
      identifier: 0,
      type: null,
      name: '',
      required: false,
    },
  ]);

  // const [tab, setTab] = useState(0);

  useEffect(() => {
    const parseQuestionChoices = (questionChoices) => {
      const optionsArray = [];
      let identifierChoice = 0;
      questionChoices.forEach((questionChoice) => {
        identifierChoice += 1;
        optionsArray.push({
          title: questionChoice.title,
          type: questionChoice.type,
          identifier: identifierChoice,
          id: questionChoice.id,
        });
      });

      return optionsArray;
    };

    const newPages = [...pages];
    for (let i = 0; i < questions.length; i += 1) {
      let memoryQuantity = null;
      let memoryUnit = null;
      if (questions[i].minMemory) {
        [memoryQuantity, memoryUnit] = questions[i].minMemory.split(' ');
      }

      if ([IMAGE, MULTIPLE_IMAGE_QUESTION].includes(questions[i].type)) {
        const kpiDetailsPage = [];
        const shareKpi = {};
        questions[i].TeleauditKpis.forEach((teleauditKpi) => {
          if (teleauditKpi.KpiDetail.teleauditType === 'binary') {
            const kpi = {
              title: teleauditKpi.KpiDetail.name,
              description: teleauditKpi.KpiDetail.description,
              teleauditType: teleauditKpi.KpiDetail.teleauditType,
            };
            kpiDetailsPage.push(kpi);
          } else {
            shareKpi.teleauditType = teleauditKpi.KpiDetail.teleauditType;
            shareKpi.target = teleauditKpi.KpiDetail.target;
          }
        });

        newPages[i] = {
          ...newPages[i],
          identifier: `${(i + 1)}`,
          type: questions[i].type,
          title: questions[i].title,
          description: questions[i].description,
          name: questions[i].type, // arreglar mayusculas mapeando a las constantes
          emojiScale: questions[i].emojiScale || null,
          emojiSet: questions[i].emojiSet || null,
          imageKey: questions[i].imageKey || null,
          imageQuality: questions[i].imageQuality || null,
          imageUrl: questions[i].imageUrl || null,
          imageUrlExpiration: questions[i].imageUrlExpiration || null,
          questionChoices: questions[i].QuestionChoices
            ? parseQuestionChoices(questions[i].QuestionChoices) : null,
          binaryKpis: kpiDetailsPage,
          sendEmail: questions[i].sendEmail || false,
          questionEmails: questions[i].QuestionEmails || null,
          required: questions[i].required || false,
          shareKpi,
          questionLogic: [{
            questionIdentifier: `${(i + 1)}`,
            questionChoiceIdentifier: null, // revisar PENDIENTE
            nextQuestionIdentifier: `${(i + 2)}`,
          }],
          requiresTeleaudit: questions[i].requiresTeleaudit || false,
          databaseId: questions[i].id || null,
          memoryQuantity,
          memoryUnit,
          videoQuality: questions[i].videoQuality || null,
        };
      } else {
        if (questions[i].isSwitchStock) {
          setSwitchStock(i + 1);
        }

        const sortedQuestionChoices = [...questions[i].QuestionChoices].sort(
          (a, b) => a.id - b.id,
        );
        const parseQuestionLogic = [];
        if (questions[i].OriginalQuestionLogics.length > 0) {
          questions[i].OriginalQuestionLogics.forEach((questionLogic) => {
            const nextId = questions.findIndex(
              (question) => question.id === parseInt(questionLogic.nextQuestionId, 10),
            );
            const questionChoiceIdentifier = sortedQuestionChoices.findIndex(
              (questionChoice) => questionChoice.id === questionLogic.questionChoiceId,
            );
            if (nextId > -1 && questionChoiceIdentifier > -1) {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: `${(questionChoiceIdentifier + 1)}`,
                nextQuestionIdentifier: questions[nextId].position.toString(),
              });
            } else if (nextId > -1) {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: null,
                nextQuestionIdentifier: `${(nextId + 1)}`,
              });
            } else {
              parseQuestionLogic.push({
                questionIdentifier: `${(i + 1)}`,
                questionChoiceIdentifier: null,
                nextQuestionIdentifier: `${(i + 2)}`,
              });
            }
          });
        } else {
          parseQuestionLogic.push({
            questionIdentifier: `${(i + 1)}`,
            questionChoiceIdentifier: null,
            nextQuestionIdentifier: `${(questions.length + 1)}`,
          });
        }

        newPages[i] = {
          ...newPages[i],
          identifier: `${(i + 1)}`,
          type: questions[i].type,
          title: questions[i].title,
          description: questions[i].description,
          name: questions[i].type, // arreglar mayusculas mapeando a las constantes
          emojiScale: questions[i].emojiScale || null,
          emojiSet: questions[i].emojiSet || null,
          imageKey: questions[i].imageKey || null,
          imageQuality: questions[i].imageQuality || null,
          imageUrl: questions[i].imageUrl || null,
          imageUrlExpiration: questions[i].imageUrlExpiration || null,
          sendEmail: questions[i].sendEmail || false,
          questionEmails: questions[i].QuestionEmails || null,
          questionChoices: sortedQuestionChoices
            ? parseQuestionChoices(sortedQuestionChoices) : null,
          questionLogic: parseQuestionLogic,
          required: questions[i].required || false,
          requiresTeleaudit: questions[i].requiresTeleaudit || false,
          databaseId: questions[i].id || null,
          isSwitchStock: questions[i].isSwitchStock,
          memoryQuantity,
          memoryUnit,
          videoQuality: questions[i].videoQuality || null,
        };
      }
    }

    setPages([...newPages]);
  }, [questions]);

  useEffect(() => {
    const questionSwitchStock = questions.findIndex((q) => q.isSwitchStock === true);
    if (questionSwitchStock !== -1) {
      setSwitchStock(questionSwitchStock + 1);
    }
  }, [questions]);

  // Change the tool tab
  // const handleChangeTab = (event, newTab) => {
  //   setTab(newTab);
  // };

  // const handleGeneralView = () => {
  //   setGeneralView((prevState) => !prevState);
  // };

  const handleViewQuestion = (event) => {
    const { id } = event.currentTarget;

    if (id === viewDetail) {
      setViewDetail(null);
      return;
    }
    setViewDetail(id);
  };

  // Select page
  const handleSelectPage = (event) => {
    const [type, identifier] = event.currentTarget.id.split('-');

    if (type === 'null') return;

    setSelectedPage(identifier);
  };

  const generatePage = (info, identifier) => {
    const pageTypes = {
      [SIMPLE_SELECTION]: <SimpleSelection identifier={identifier} info={info}
      formType={formType} mode={VIEW_MODE} />,
      [MULTIPLE_SELECTION]: <MultipleSelection identifier={identifier} info={info}
      formType={formType} mode={VIEW_MODE} />,
      [LONG_ANSWER]: <LongAnswer identifier={identifier} info={info} mode={VIEW_MODE} />,
      [SHORT_ANSWER]: <ShortAnswer identifier={identifier} info={info} mode={VIEW_MODE} />,
      [DROP_DOWN_MENU]: <DropDownMenu identifier={identifier} info={info} mode={VIEW_MODE} />,
      [SATISFACTION]: <Satisfaction identifier={identifier} info={info} mode={VIEW_MODE} />,
      [IMAGE]: <ImageAnswer identifier={identifier} info={info} mode={VIEW_MODE} />,
      [YES_NO_QUESTION]: (
        <YesNoQuestion
          identifier={identifier}
          info={info}
          mode={VIEW_MODE}
          switchStock={switchStock}
          isSwitchStock={info.isSwitchStock}
          setSwitchStock={setSwitchStock}
        />
      ),
      [NUMERIC_ANSWER]: <NumericAnswer identifier={identifier} info={info} mode={VIEW_MODE} />,
      [DATE]: <DateAnswer identifier={identifier} info={info} mode={VIEW_MODE} />,
      [WELCOME_PAGE]: <WelcomePage identifier={identifier} info={info} mode={VIEW_MODE} />,
      [RESUME_PAGE]: <ResumePage identifier={identifier} info={info} mode={VIEW_MODE} />,
      [CONTENT]: <WelcomePage identifier={identifier} info={info} mode={VIEW_MODE} />,
      [END_PAGE]: <WelcomePage identifier={identifier} info={info} mode={VIEW_MODE} />,
      [NPS_QUESTION]: <NpsQuestion identifier={identifier} info={info} mode={VIEW_MODE} />,
      [MULTIPLE_IMAGE_QUESTION]: <MultipleImageQuestion identifier={identifier} info={info}
        mode={VIEW_MODE} />,
      [SIGNATURE_QUESTION]: <SignatureQuestion identifier={identifier} info={info}
        mode={VIEW_MODE} />,
      [BARCODE_QUESTION]: <BarcodeQuestion identifier={identifier} info={info} mode={VIEW_MODE} />,
      [DECIMAL_ANSWER]: <DecimalAnswer identifier={identifier} info={info} mode={VIEW_MODE} />,
      [PRICE]: <Price identifier={identifier} info={info} mode={VIEW_MODE} />,
      [VIDEO]: <Video identifier={identifier} info={info} mode={VIEW_MODE} />,
    };

    return pageTypes[info.type];
  };

  const pageNames = {
    [SIMPLE_SELECTION]: t('task.taskEdition.simpleSelection'),
    [MULTIPLE_SELECTION]: t('task.taskEdition.multipleSelection'),
    [LONG_ANSWER]: t('task.taskEdition.longAnswer'),
    [SHORT_ANSWER]: t('task.taskEdition.shortAnswer'),
    [DROP_DOWN_MENU]: t('task.taskEdition.dropDownMenu'),
    [SATISFACTION]: t('task.taskEdition.satisfaction'),
    [IMAGE]: t('task.taskEdition.imageQuestion'),
    [YES_NO_QUESTION]: t('task.taskEdition.yesNoQuestion'),
    [NUMERIC_ANSWER]: t('task.taskEdition.numericAnswer'),
    [DATE]: t('task.taskEdition.date'),
    [WELCOME_PAGE]: t('task.taskEdition.welcomePage'),
    [RESUME_PAGE]: t('task.taskEdition.resumePage'),
    [CONTENT]: t('task.taskEdition.content'),
    [END_PAGE]: t('task.taskEdition.endPage'),
    [NPS_QUESTION]: t('task.taskEdition.npsQuestion'),
    [MULTIPLE_IMAGE_QUESTION]: t('task.taskEdition.multipleImageQuestion'),
    [SIGNATURE_QUESTION]: t('task.taskEdition.signatureQuestion'),
    [BARCODE_QUESTION]: t('task.taskEdition.barcodeQuestion'),
    [DECIMAL_ANSWER]: t('task.taskEdition.decimalAnswer'),
    [PRICE]: t('task.taskEdition.price'),
    [VIDEO]: t('task.taskEdition.video'),
  };

  const handleBack = () => {
    setTaskInfo({
      project: '',
      title: '',
      type: null,
    });
    setQuestions([]);
    setTaskId(null);
    setEditTask(false);
    setEditView(false);
    setInputValues({
      title: '',
      type: { name: '', value: '' },
      project: { name: '', value: '' },
    });
  };

  const handleDuplicateTaskInfo = () => {
    setCurrentOpen(true);
  };

  const handleDuplicateTask = () => {
    setTaskId(taskId);
    setEditTask(false);
    setDuplicateTask(true);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <IconButton
              id="backButton"
              aria-label="back"
              className={styles.backButton}
              onClick={handleBack}
            >
              <ReplyOutlined fontSize="small" />
              <span>{t('groups.actionButtons.back')}</span>
            </IconButton>
          <Typography variant="h4" className={styles.title}>
            {title}
          </Typography>
        </div>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <div className={styles.buttonsContainer}>
            <Button
              onClick={handleDuplicateTaskInfo}
            >
            {t('task.taskEdition.duplicateTask')}
            </Button>
           </div>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <div className={styles.surveyContent}>
        <div className={styles.editionArea}>
          {questions.map((question, index) => (
            <div
              key={`${question.type}-${index}`}
              id={`${question.type}-${index}`}
              onClick={handleSelectPage}
            >
              <div
                className={clsx(styles.pageInfo, {
                  [styles.selectedPageGeneralView]:
                    selectedPage === `${index}` && generalView && !(viewDetail === `${index}`),
                  [styles.generalViewPageTitle]:
                    generalView && !(viewDetail === `${index}`),
                })}
              >
                <div>
                  <p className={styles.pageTitle}>
                    {t('task.taskEdition.page')} {index + 1} {question.type ? `: ${pageNames[question.type]}` : ''}
                  </p>
                </div>
                <div>
                  {question.type && generalView ? (
                    <>
                      <Button id={index} onClick={handleViewQuestion}>
                        <VisibilityOutlined className={styles.viewQuestion} />
                      </Button>
                    </>
                  ) : null}
                  {question.type && !generalView
                  && ![WELCOME_PAGE, END_PAGE, CONTENT, RESUME_PAGE].includes(question.type) ? (
                    <>
                      <p className={styles.pageTitle}>{t('task.taskEdition.obligatory')}</p>
                      <FormControlLabel
                        control={
                          <Switch name={`${index}`} className={styles.switch} checked={question.required} disabled />
                        }
                        label=""
                      />
                    </>
                    ) : null}
                </div>
              </div>
              <div
                className={clsx({
                  [styles.selectedPageStyle]:
                    selectedPage === `${index}`,
                  [styles.hideContent]:
                    generalView && !(viewDetail === `${index}`),
                })}
              >
                {generatePage(question, index + 1)}
              </div>
            </div>
          ))}
        </div>
        {/* <div className={styles.toolsContainer}>
          <p className={styles.sectionTitle}>
            {t('task.taskEdition.tools')}
          </p>
          <Paper>
            <StyledTabs
              value={tab}
              variant="scrollable"
              onChange={handleChangeTab}
            >
              <StyledTab label={t('task.taskEdition.tabOne')} />
              <StyledTab label={t('task.taskEdition.tabTwo')} />
            </StyledTabs>
            <div className={styles.toolsContent}>
              {tab === 1 ? (
                <div className={styles.connectionButton}>
                  <p>
                    <b>{t('task.questionLogic.content')}</b>
                    <br />
                    {t('task.questionLogic.connections')}
                  </p>
                  <IconButton
                    aria-label="connections"
                    onClick={() => setOpenConnections(true)}
                    disabled={questions.length < 2
                      || (questions.length === 2 && !questions[1].type)
                      || !questions[questions.length - 1].type}
                  >
                    <ChevronRight />
                  </IconButton>
                </div>
              ) : null}
              {!selectedPage && (
                <p>
                  <em>{t('task.taskEdition.toolsPlaceholder')}</em>
                </p>
              )}
              <div>
                <Button className={styles.button} onClick={handleGeneralView}>
                  {generalView
                    ? t('task.taskEdition.extendedViewButton')
                    : t('task.taskEdition.generalViewButton')}
                </Button>
              </div>
            </div>
          </Paper>
        </div> */}
      </div>
      {openConnections ? (
        <QuestionLogic
          open={openConnections}
          setOpen={setOpenConnections}
          inputValues={pages}
          setInputValues={setPages}
          edit={false}
          view={true}
        />
      ) : null}
      {defaultOpen
        ? (
          <CreateTaskModal
            defaultOpen={defaultOpen}
            setCurrentOpen={setCurrentOpen}
            setEditView={setEditView}
            inputValues={inputValues}
            setInputValues={setInputValues}
            duplicating={true}
            handleDuplicateTask={handleDuplicateTask}
            taskInfo={taskInfo}
          />
        ) : null}
    </>
  );
}

export default TaskDetail;
