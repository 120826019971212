import React, { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Typography,
  Paper,
} from '@material-ui/core';

// components
import Snackbar from '../../components/Snackbar';
import Button from '../../components/Button';

import TasksTable from './table';
import TaskDetail from './taskDetail';
import DuplicateTask from './DuplicateTask';
import EditTask from './EditTask';

// requests
import { getTask } from '../../requests/api/task';
import { getTaskInstancesOfTask } from '../../requests/api/taskInstance';

import useStyles from './styles';
import TaskAssociation from '../Tasks/TaskAssociation';
import CreateTaskModal from './CreateTask';
import CreateTask from './CreateTask/view';

function Tasks() {
  const styles = useStyles();
  const { t } = useTranslation();

  // snackbar
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const [open, setOpen] = useState(false);
  const [editView, setEditView] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [associate, setAssociate] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: '',
    type: { name: '', value: '' },
    project: { name: '', value: '' },
  });
  const [title, setTitle] = useState(null);
  const [taskId, setTaskId] = useState(null);

  const [taskInfo, setTaskInfo] = useState({
    project: '',
    title: '',
    type: '',
    requiredGeoreference: false,
    revisionPolicy: '',
  });
  const [taskInstances, setTaskInstances] = useState([]);

  const [verticalForm, setVerticalForm] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [duplicateTask, setDuplicateTask] = useState(false);

  const tableHeaders = [
    {
      id: 'name',
      label: t('task.columnNames.title'),
    },
    {
      id: 'type',
      label: t('task.columnNames.type'),
    },
    {
      id: 'taskAssigned',
      label: t('task.columnNames.taskAssigned'),
    },
    {
      id: 'createdAt',
      label: t('task.columnNames.createdAt'),
    },
    {
      id: 'actions',
      label: t('task.columnNames.actions'),
    },
  ];

  const [values, setValues] = useState({
    isSwitchStock: false,
    productInformation: false,
  });
  const [switchStock, setSwitchStock] = useState(null);

  useEffect(() => {
    const getTaskById = async () => {
      const { data, success } = await getTask(taskId);
      if (success) {
        const taskData = data.data.task;
        setTaskInfo({
          title: taskData.title,
          project: taskData.Project.id,
          type: { name: taskData.type },
          requiredGeoreference: taskData.requiredGeoreference,
          revisionPolicy: taskData.revisionPolicy,
        });

        setVerticalForm(taskData.vertical);
        setTitle(taskData.title);
        if (taskData.Questions.length > 0) {
          const sortedQuestions = taskData.Questions.sort((a, b) => a.position - b.position);
          setQuestions(sortedQuestions);
        }

        const activeTaskInstances = await getTaskInstancesOfTask(taskId);
        if (success) {
          setTaskInstances(activeTaskInstances.data.data);
        }
      }
    };

    if (taskId) {
      getTaskById();
    }
  }, [taskId]);

  const renderContent = () => {
    if (!duplicateTask) {
      // TASKS TABLE VIEW
      if (!editView && !taskId) {
        return (
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography variant='h4' className={styles.title}>{t('task.title')}</Typography>
            <Button
              id="save-btn"
              variant="outlined"
              className={styles.createButton}
              text={t('task.createButton')}
              height="40px"
              width="220px"
              backgroundColor='theme-secondary-blue'
              color='white'
              borderRadius='10px'
              onClick={() => setOpen(true)}
            />
          </div>
          <Paper className={styles.taskTable}>
            <TasksTable
              setTaskId={setTaskId}
              setEditTask={setEditTask}
              setAssociate={setAssociate}
              setSeverity={setSeverity}
              setMessage={setMessage}
              tableHeaders={tableHeaders}
              setOpen={setOpen}
              allowCheckIn
              edit={false}
              readOnly
            />
            <CreateTaskModal
              defaultOpen={open}
              setCurrentOpen={setOpen}
              setEditView={setEditView}
              inputValues={inputValues}
              setInputValues={setInputValues}
              taskInfo={taskInfo}
            />
            <Snackbar
              open={message !== ''}
              message={message}
              severity={severity}
              onClose={() => setMessage('')}
            />
          </Paper>
        </div>
        );
      }

      // CREATION VIEW
      if (editView && !taskId && !associate) {
        return (
        <div className={styles.content}>
          <CreateTask
            inputValues={inputValues}
            project={inputValues.project}
            verticalForm={verticalForm}
            setVerticalForm={setVerticalForm}
            formType={inputValues.type}
            setEditView={setEditView}
            setModalOpen={setOpen}
            setInputValues={setInputValues}
            valuesProp={values}
            setValuesProp={setValues}
            switchStock={switchStock}
            setSwitchStock={setSwitchStock}
          />
        </div>
        );
      }

      // TASK DETAIL VIEW
      if (taskId && !editTask && !associate) {
        return (
        <div className={styles.content}>
          <TaskDetail
            questions={questions}
            project={taskInfo.project}
            title={taskInfo.title}
            formType={taskInfo.type}
            setTaskId={setTaskId}
            setTaskInfo={setTaskInfo}
            setQuestions={setQuestions}
            setDuplicateTask={setDuplicateTask}
            setEditTask={setEditTask}
            defaultOpen={open}
            setCurrentOpen={setOpen}
            setEditView={setEditView}
            inputValues={inputValues}
            setInputValues={setInputValues}
            taskInfo={taskInfo}
            taskId={taskId}
          />
        </div>
        );
      }

      // EDIT TASK VIEW
      if (taskId && editTask && !associate) {
        return (
        <div className={styles.content}>
          <EditTask
            title={title}
            setTitle={setTitle}
            project={inputValues.project}
            formType={inputValues.type}
            setEditView={setEditView}
            setModalOpen={setOpen}
            setInputValues={setInputValues}
            inputValues={inputValues}
            questions={questions}
            setQuestions={setQuestions}
            setTaskInfo={setTaskInfo}
            taskId={taskId}
            setTaskId={setTaskId}
            setEditTask={setEditTask}
            taskInfo={taskInfo}
            verticalForm={verticalForm}
            setVerticalForm={setVerticalForm}
          />
        </div>
        );
      }
    }

    // DUPLICATE TASK VIEW
    if (duplicateTask) {
      return (
        <div className={styles.content}>
          <DuplicateTask
            taskId={taskId}
            setTaskId={setTaskId}
            questions={questions}
            project={taskInfo.project}
            setModalOpen={setOpen}
            title={taskInfo.title}
            formType={taskInfo.type}
            setTaskInfo={setTaskInfo}
            setEditView={setEditView}
            setQuestions={setQuestions}
            taskInfo={taskInfo}
            setDuplicateTask={setDuplicateTask}
            setInputValues={setInputValues}
            inputValues={inputValues}
            setCurrentOpen={setOpen}
            verticalForm={verticalForm}
            setVerticalForm={setVerticalForm}
            valuesProp={values}
            setValuesProp={setValues}
            switchStock={switchStock}
            setSwitchStock={setSwitchStock}
          />
        </div>
      );
    }

    if (associate || (editTask && taskId)) {
      return (
        <TaskAssociation
          taskId={taskId}
          setTaskId={setTaskId}
          setAssociate={setAssociate}
          name={taskInfo.title}
          project={taskInfo.project}
          cluster={''}
          edit={editTask}
          taskInstances={taskInstances}
          setEdit={setEditTask}
          taskInfo={taskInfo}
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      {renderContent()}
    </Fragment>
  );
}

export default Tasks;
