/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// core
import {
  Button, IconButton, Accordion, AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@material-ui/core';
// icons
import DeleteIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GestureIcon from '@material-ui/icons/Gesture'; // para signature
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon'; // para satisfaccion
import LooksOneIcon from '@material-ui/icons/LooksOne'; // numeric
import LooksDecimalIcon from '@material-ui/icons/ConfirmationNumber'; // numeric
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'; // para imagen multiple
import PhotoIcon from '@material-ui/icons/Photo'; // para imagen simple
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'; // para selection
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown'; // para Yes no
import MenuIcon from '@material-ui/icons/Menu'; // dropdown menu
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'; // para content *
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'; // multiple sleeccion
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify'; // long asnwer
import DehazeIcon from '@material-ui/icons/Dehaze'; // short answer
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'; // date
import LineWeightIcon from '@material-ui/icons/LineWeight'; // barcode
import ExposureIcon from '@material-ui/icons/Exposure'; // nps
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';
import Message from '@material-ui/icons/Message';
import PriceIcon from '@material-ui/icons/AttachMoney';
import VideoIcon from '@material-ui/icons/VideoLibrary';

import useStyles from '../styles';

// const
import {
  DROP_DOWN_MENU, END_PAGE, LONG_ANSWER, SIMPLE_SELECTION,
  SATISFACTION, SHORT_ANSWER, MULTIPLE_SELECTION, WELCOME_PAGE,
  YES_NO_QUESTION, NUMERIC_ANSWER, DATE, NPS_QUESTION, CONTENT,
  MULTIPLE_IMAGE_QUESTION, SIGNATURE_QUESTION, BARCODE_QUESTION,
  RESUME_PAGE, DECIMAL_ANSWER, PRICE, VIDEO,
} from '../../../utils/const/pageTypes';

import {
  ENTRENAMIENTO,
} from '../../../utils/const/formTypes';

import { FIRST_IDENTIFIER, IMAGE } from '../../../utils/const/questions';

function SelectPage({
  pages, setPages, formType, identifier, setSwitchStock, verticalForm,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const staticQuestions = {
      label: t('task.taskForm.questionGroupsTypes.static'),
      index: 0,
      questions: [{
        label: t('task.taskEdition.welcomePage'),
        color: '#56CCF2',
        icon: <PlayCircleOutlineRoundedIcon />,
      }, {
        label: t('task.taskEdition.endPage'),
        color: '#56CCF2',
        icon: <StopRoundedIcon />,
      }, {
        label: t('task.taskEdition.content'),
        color: '#56CCF2',
        icon: <FormatQuoteIcon />,
      }, {
        label: t('task.taskEdition.resumePage'),
        color: '#56CCF2',
        icon: <Message />,
      },
      ],
    };
    const imageQuestions = {
      label: t('task.taskForm.questionGroupsTypes.image'),
      index: 1,
      questions: [{
        label: t('task.taskEdition.imageQuestion'),
        color: '#2F80ED',
        icon: <PhotoIcon />,
      }, {
        label: t('task.taskEdition.multipleImageQuestion'),
        color: '#2F80ED',
        icon: <PhotoLibraryIcon />,
      }],
    };
    const textQuestions = {
      label: t('task.taskForm.questionGroupsTypes.text'),
      index: 2,
      questions: [{
        label: t('task.taskEdition.shortAnswer'),
        color: '#2D9CDB',
        icon: <DehazeIcon />,
      },
      {
        label: t('task.taskEdition.longAnswer'),
        color: '#2D9CDB',
        icon: <FormatAlignJustifyIcon />,
      }],
    };
    const selectionQuestions = {
      label: t('task.taskForm.questionGroupsTypes.selection'),
      index: 3,
      questions: [{
        label: t('task.taskEdition.simpleSelection'),
        color: '#56CCF2',
        icon: <RadioButtonCheckedIcon />,
      }, {
        label: t('task.taskEdition.multipleSelection'),
        color: '#56CCF2',
        icon: <FormatListBulletedIcon />,
      }, {
        label: t('task.taskEdition.dropDownMenu'),
        color: '#2F80ED',
        icon: <MenuIcon />,
      }, {
        label: t('task.taskEdition.satisfaction'),
        color: '#2F80ED',
        icon: <InsertEmoticonIcon />,
      }, {
        label: t('task.taskEdition.yesNoQuestion'),
        color: '#2F80ED',
        icon: <ThumbsUpDownIcon />,
      }, {
        label: t('task.taskEdition.npsQuestion'),
        color: '#2F80ED',
        icon: <ExposureIcon />,
      }],
    };
    const otherQuestions = {
      label: t('task.taskForm.questionGroupsTypes.other'),
      index: 5,
      questions: [{
        label: t('task.taskEdition.date'),
        color: '#2F80ED',
        icon: <CalendarTodayIcon />,
      }, {
        label: t('task.taskEdition.numericAnswer'),
        color: '#2F80ED',
        icon: <LooksOneIcon />,
      }, {
        label: t('task.taskEdition.signatureQuestion'),
        color: '#2F80ED',
        icon: <GestureIcon />,
      }, {
        label: t('task.taskEdition.barcodeQuestion'),
        color: '#2F80ED',
        icon: <LineWeightIcon rotate='90deg' />,
      }, {
        label: t('task.taskEdition.decimalAnswer'),
        color: '#2F80ED',
        icon: <LooksDecimalIcon />,
      }, {
        label: t('task.taskEdition.price'),
        color: '#2F80ED',
        icon: <PriceIcon />,
      }, {
        label: t('task.taskEdition.videoQuestion'),
        color: '#2F80ED',
        icon: <VideoIcon />,
      }],
    };

    if (formType.name === ENTRENAMIENTO) {
      setOptions([staticQuestions, selectionQuestions]);
    } else {
      setOptions([
        staticQuestions,
        imageQuestions,
        textQuestions,
        selectionQuestions,
        otherQuestions,
      ]);
    }
  }, []);

  const handleSelectPagetype = (event) => {
    const [option] = event.currentTarget.id.split('-');

    let saveOption = null;
    switch (option) {
      case t('task.taskEdition.resumePage'):
        saveOption = RESUME_PAGE;
        break;
      case t('task.taskEdition.welcomePage'):
        saveOption = WELCOME_PAGE;
        break;
      case t('task.taskEdition.endPage'):
        saveOption = END_PAGE;
        break;
      case t('task.taskEdition.content'):
        saveOption = CONTENT;
        break;
      case t('task.taskEdition.simpleSelection'):
        saveOption = SIMPLE_SELECTION;
        break;
      case t('task.taskEdition.multipleSelection'):
        saveOption = MULTIPLE_SELECTION;
        break;
      case t('task.taskEdition.shortAnswer'):
        saveOption = SHORT_ANSWER;
        break;
      case t('task.taskEdition.longAnswer'):
        saveOption = LONG_ANSWER;
        break;
      case t('task.taskEdition.dropDownMenu').split('-')[0]:
        saveOption = DROP_DOWN_MENU;
        break;
      case t('task.taskEdition.date'):
        saveOption = DATE;
        break;
      case t('task.taskEdition.satisfaction'):
        saveOption = SATISFACTION;
        break;
      case t('task.taskEdition.imageQuestion'):
        saveOption = IMAGE;
        break;
      case t('task.taskEdition.yesNoQuestion'):
        saveOption = YES_NO_QUESTION;
        break;
      case t('task.taskEdition.numericAnswer'):
        saveOption = NUMERIC_ANSWER;
        break;
      case t('task.taskEdition.npsQuestion'):
        saveOption = NPS_QUESTION;
        break;
      case t('task.taskEdition.multipleImageQuestion'):
        saveOption = MULTIPLE_IMAGE_QUESTION;
        break;
      case t('task.taskEdition.signatureQuestion'):
        saveOption = SIGNATURE_QUESTION;
        break;
      case t('task.taskEdition.barcodeQuestion'):
        saveOption = BARCODE_QUESTION;
        break;
      case t('task.taskEdition.decimalAnswer'):
        saveOption = DECIMAL_ANSWER;
        break;
      case t('task.taskEdition.price'):
        saveOption = PRICE;
        break;
      case t('task.taskEdition.videoQuestion'):
        saveOption = VIDEO;
        break;
      default:
        saveOption = WELCOME_PAGE;
        break;
    }
    if (pages.length === 1 && !pages[0].type) {
      setPages([{
        identifier: FIRST_IDENTIFIER,
        name: option,
        type: saveOption,
        required: false,
      }]);
      return;
    }
    // Find the question without question type selected.
    const pageIndexSelect = pages.findIndex((p) => p.identifier === identifier.toString());
    if (pages[pageIndexSelect] && !pages[pageIndexSelect].type) {
      const newPages = pages.filter((p) => p.identifier !== identifier);

      // Save the new question type selected
      const copiedItems = [...newPages];
      copiedItems.splice(pageIndexSelect, 0, {
        identifier,
        name: option,
        type: saveOption,
        required: false,
      });

      // Updates identifier question
      copiedItems.forEach((copiedItem, index) => {
        // eslint-disable-next-line no-param-reassign
        copiedItem.identifier = `${(index + 1)}`;
      });
      const switchStockIndex = copiedItems.findIndex((ci) => ci.isSwitchStock);

      if (switchStockIndex !== -1) {
        setSwitchStock(copiedItems[switchStockIndex].identifier, 10);
      }
      setPages(copiedItems);
    }
  };

  const handleDeleteSelectPage = () => {
    const newPages = pages.filter((p) => p.identifier !== identifier);

    // Updates identifier question
    newPages.forEach((copiedItem, index) => {
      // eslint-disable-next-line no-param-reassign
      copiedItem.identifier = `${(index + 1)}`;
    });
    const switchStockIndex = newPages.findIndex((ci) => ci.isSwitchStock);

    if (switchStockIndex !== -1) {
      setSwitchStock(newPages[switchStockIndex].identifier, 10);
    }
    setPages(newPages);
  };

  const renderedPages = options.map((option) => (
    <Accordion key={option.index}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${option.index}-content`}
        id={`panel${option.index}a-header`}
      >
        <p className={styles.subTitle}>{option.label}</p>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.optionsContainer}>
          {option.questions.map((pageOption, index) => (
            <Tooltip
              title={verticalForm && pageOption.label === t('task.taskEdition.resumePage') ? t('task.taskEdition.resumePageTooltip') : ''}
              key={index}
            >
              <Button
                id={`${pageOption.label}-${index}`}
                onClick={verticalForm && pageOption.label === t('task.taskEdition.resumePage') ? null : handleSelectPagetype}
                className={verticalForm && pageOption.label === t('task.taskEdition.resumePage') ? styles.disabledButton : ''}
              >
                <svg
                  width="35"
                  height="35"
                  transform={pageOption.label === t('task.taskEdition.barcodeQuestion') ? 'rotate(90)' : null}>
                  {pageOption.icon}
                </svg>
                <p>{pageOption.label}</p>
              </Button>
            </Tooltip>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  ));

  return (
    <>
      <IconButton
        onClick={handleDeleteSelectPage}
        className={styles.deleteButtonSelectPage}
      >
        <DeleteIcon />
      </IconButton>
      <div className={`${styles.root} ${styles.selectPageContainer}`}>
        <div>
          <p className={styles.subTitle}>{t('task.taskEdition.selectPage')}</p>
          {renderedPages}
        </div>
      </div>
    </>
  );
}

export default SelectPage;
