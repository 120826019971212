import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(5),
  },
  surveyTable: {
    '& .MuiTableCell-root': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-head': {
      color: theme.palette.primary.gray,
    },
    '& .MuiTableCell-paddingNone': {
      color: theme.palette.primary.mainBlue,
      fontWeight: 'bold',
    },
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(44, 157, 201, 0.05)',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgb(44, 157, 201, 0.05)',
    },
  },
  iconsCell: {
    '& svg': {
      color: theme.palette.primary.gray,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.blue,
      },
    },
    '&:hover': {
      '& button': {
        backgroundColor: 'transparent',
      },
    },
    fontSize: '10px',
  },
  title: {
    flexGrow: 1,
    fontSize: '24px',
    marginBottom: '8px',
    fontWeight: 'bold',
    color: theme.palette.primary.mainBlue,
  },
  subTitle: {
    fontSize: '16px',
    color: theme.palette.primary.gray,
    marginBottom: '20px',
  },
  noContent: {
    height: '710px',
  },
  progress: {
    height: '610px',
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& div': {
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '200',
    },
  },
  surveys: {
    display: 'table-cell',
    textAlign: 'center',
    padding: '50px',
    '& svg': {
      width: '40px',
      height: '40px',
      color: theme.palette.primary.main,
    },
    '& h4': {
      color: theme.palette.primary.main,
      fontWeight: '600',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      fontSize: '16px',
      marginTop: '10px',
      color: theme.palette.secondary.gray,
    },
    '& span': {
      borderBottom: `1px solid ${theme.palette.secondary.blue}`,
    },
    '& button': {
      color: theme.palette.primary.white,
      float: 'right',
    },
  },
  tableHeader: {
    color: theme.palette.secondary.gray,
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
    '& .MuiCheckbox-indeterminate ': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  tooltipWidget: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    borderRadius: '20px',
    backgroundColor: theme.palette.primary.blue,
    padding: '10px',
  },
  tooltipNumber: {
    color: theme.palette.primary.white,
    fontSize: '15px',
  },
  tooltipBar: {
    textDecoration: 'none',
    color: theme.palette.primary.white,
    fontSize: '15px',
  },
  tooltipText: {
    color: theme.palette.primary.white,
    fontSize: '15px',
  },
  ticketContainer: {
    paddingTop: '10px',
  },
  checkbox: {
    '& .Mui-checked': {
      color: theme.palette.secondary.blue,
      opacity: 0.5,
    },
  },
  caption: {
    marginBottom: '20px !important',
    color: theme.palette.primary.warning,
  },
  icon: {
    border: `1px solid ${theme.palette.primary.warning}`,
    borderRadius: '50%',
    padding: '2px',
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '5px',
    '& svg': {
      height: '18px',
      width: '18px',
    },
  },
}));

export default useStyles;
