import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// core
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Grid,
  Grow,
  TableSortLabel,
} from '@material-ui/core';
// icons
import { withStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import EditIcon from '@material-ui/icons/Edit';
import EditTaskIcon from '@material-ui/icons/BorderColor';
import Arrow from '@material-ui/icons/CompareArrowsOutlined';

// components
import Pagination from '../../components/Pagination';
import TableToolbarActions from '../../components/TableToolbarActions';
import Button from '../../components/Button';

import useStyles from './styles';

// requests
import { getSurveysPerPage, deleteSurvey } from '../../requests/api/survey';
// const
import { UNAUTHORIZED } from '../../utils/const/http';
import { SUPER_ADMIN } from '../../utils/const/user';
import { CHECK_IN, PRODUCT } from '../../utils/const/formTypes';
// utils
import { isSelected, handleSelect } from '../../utils/selectFunctions';
import load from '../../utils/load';
import { formatDateAndTime } from '../../utils/date';

const DrawerTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.white,
    textAlign: 'start',
    width: '100%',
    maxWidth: '100%',
    fontSize: '1em',
    borderRadius: '20px',
  },
  arrow: {
    color: theme.palette.primary.blue,
    border: '0px',
  },
}))(Tooltip);

const SmallDrawerTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.blue,
    color: theme.palette.primary.white,
    textAlign: 'start',
    width: '100%',
    maxWidth: '90%',
    fontSize: '12px',
    borderRadius: '20px',
  },
  arrow: {
    color: theme.palette.primary.blue,
    border: '0px',
  },
}))(Tooltip);

function TasksTable({
  setTaskId,
  setAssociate,
  table,
  setMessage,
  setSeverity,
  setSelectToAdd,
  selectedForms,
  tableHeaders,
  setOpen,
  category,
  allowCheckIn,
  setCheckInForms,
  checkInForms,
  addedCheckIn,
  isTraining,
  categoriesProductsInListing,
  edit,
  setEditTask,
  readOnly,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  // table states
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsToShow, setRowsToShow] = useState([]);
  const [surveysQuantity, setSurveysQuantity] = useState(0);

  // auxiliar statess
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  // Filter states
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('');
  const [column, setColumn] = useState(false);

  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('createdAt');

  const account = useSelector((state) => state.account);
  const company = useSelector((state) => state.company);

  const handleView = async (event) => {
    const { id } = event.currentTarget;
    setSelected([]);
    setTaskId(id);
  };

  const handleEdit = async (event) => {
    const { id } = event.currentTarget;
    setSelected([]);
    setTaskId(id);
    setEditTask(true);
  };

  const handleAssociate = (event) => {
    const { id } = event.currentTarget;
    setTaskId(id);
    setAssociate(true);
  };

  const handleEditAssociations = (event) => {
    const { id } = event.currentTarget;
    setTaskId(id);
    setEditTask(true);
    setAssociate(true);
  };

  const handleDeleteSelected = async (row) => {
    let surveyId;
    if (typeof row === 'object') {
      surveyId = parseInt(row.surveyId, 10);
    } else {
      surveyId = row;
    }
    const response = await deleteSurvey(surveyId);
    return response;
  };

  useEffect(() => {
    load(200, setLoading);
  }, [rowsToShow]);

  useEffect(() => {
    if (account.user.role !== SUPER_ADMIN) {
      window.location.href = '/home';
    }
    const requestSearch = async (pageOfTable, limit, searchValue, columnName, order) => {
      setUpdate(false);
      const response = await getSurveysPerPage(
        pageOfTable, limit, searchValue, columnName, order,
      );

      const arrayTrain = [];
      let arrayTrainCount = 0;
      if (response.success) {
        if (readOnly) {
          setRowsToShow(response.data.data.surveys);
          setSurveysQuantity(response.data.data.totalSurveys);
          return;
        }
        if (isTraining) {
          for (let i = 0; i < response.data.data.surveys.length; i += 1) {
            if (response.data.data.surveys[i].type === 'entrenamiento') {
              arrayTrain.push(response.data.data.surveys[i]);
              arrayTrainCount += 1;
            }
          }
          setRowsToShow(arrayTrain);
          setSurveysQuantity(arrayTrainCount);
        } else {
          setRowsToShow(response.data.data.surveys);
          setSurveysQuantity(response.data.data.totalSurveys);
        }
        if (table) {
          const checkInIds = response.data.data.surveys.map((survey) => {
            if (survey.type === CHECK_IN) {
              return `${survey.id}`;
            }
            return null;
          });
          setCheckInForms(checkInIds);
        }
      }
      if (response.status === UNAUTHORIZED.status) {
        window.location.href = '/home';
      }
    };

    requestSearch(page, rowsPerPage, filter, column, sortOrder);
  }, [column, filter, page, rowsPerPage, account.user.role,
    update, setCheckInForms, table, company, sortOrder]);

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const columnWidths = {
    checkbox: '50px',
    name: '120px',
    type: '60px',
    taskAssigned: '20px',
    createdAt: '110px',
    actions: '180px',
  };

  const content = () => {
    if (!surveysQuantity) {
      return (
        <TableRow className={styles.noContent}>
          <TableCell colSpan={7} className={styles.surveys}>
            <h4>{t('task.noContent')}</h4>
            <Button
              id="save-btn"
              variant="outlined"
              className={styles.createButton}
              text={t('task.createButton')}
              height="40px"
              width="220px"
              backgroundColor='theme-secondary-blue'
              color='white'
              borderRadius='10px'
              onClick={() => setOpen(true)}
            />
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {rowsToShow
          .map((row, index) => {
            const isItemSelected = isSelected(row.id, table ? selectedForms : selected, table);
            const labelId = `enhanced-table-checkbox-${index}`;

            if (!readOnly) {
              if (!allowCheckIn && row.type === CHECK_IN) {
                return null;
              }

              // Product type forms are not allowed in the Sin categoría (N/A) section.
              let start = 0;
              let end = 0;
              if (category) {
                start = category.indexOf('(') + 1;
                end = category.indexOf(')');
              }
              if (edit && ((category === 'null' || !category || category === `${t('task.noCategory')} (N/A)`)
                || !categoriesProductsInListing.includes((category.slice(start, end))))
                && row.type === PRODUCT) return null;
              // table is added to show product forms on forms tab.
              if ((table && (category === 'null' || !category || category === `${t('task.noCategory')} (N/A)`)) && row.type === PRODUCT) return null;
            }

            return (
              <TableRow
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" className={styles.checkbox}>
                  <Checkbox
                    onClick={(event) => handleSelect(event,
                      row.id,
                      table ? selectedForms : selected,
                      table ? setSelectToAdd : setSelected,
                      `${row.title}~${row.id}~${row.type}`)}
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    disabled={table
                      // Check-in forms restrictions
                      && (((addedCheckIn && row.type === CHECK_IN)
                        || (!selectedForms.find((selectedForm) => selectedForm.surveyId === `${row.id}`)
                          && selectedForms.map((selectedForm) => selectedForm.surveyId).some(
                            (form) => checkInForms.includes(form),
                          )
                          && checkInForms.includes(`${row.id}`)
                          && row.type === CHECK_IN)))}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                >
                  {row.title}
                </TableCell>
                <TableCell align="left">{row.type}</TableCell>
                {table ? <TableCell>{row.requiredVersionApp}</TableCell> : (
                  <>
                    <TableCell align="left">
                      {row.TaskInstances.length > 0 ? (
                        <>
                          <Grid container justifyContent='space-between'>
                            <Grid>
                              <DrawerTooltip
                                TransitionComponent={Grow}
                                placement="right-start"
                                interactive
                                leaveDelay={200}
                                title={
                                  <>
                                    <Grid
                                      direction="column"
                                      container
                                      alignItems="flex-start"
                                      spacing={1}
                                      className={styles.tooltipWidget}
                                    >
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Typography
                                            display="inline"
                                            align="center"
                                            className={styles.tooltipNumber}
                                            variant="h3"
                                          >
                                            {row.TaskInstances.length}
                                          </Typography>
                                          <Typography
                                            display="inline"
                                            align="center"
                                            className={styles.tooltipBar}
                                            variant="h3"
                                          >
                                            &nbsp;|&nbsp;
                                          </Typography>
                                          {row.TaskInstances.length === 1
                                            ? <Typography
                                              display="inline"
                                              align="center"
                                              className={styles.tooltipText}
                                              variant="h3"
                                            >
                                              &nbsp;&nbsp;{'Tarea asignada'}
                                            </Typography>
                                            : <Typography
                                              display="inline"
                                              align="center"
                                              className={styles.tooltipText}
                                              variant="h3"
                                            >
                                              &nbsp;&nbsp;{'Tareas asignadas'}
                                            </Typography>
                                          }
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                }>
                                <IconButton>
                                  <DoneIcon fontSize="small" />
                                </IconButton>
                              </DrawerTooltip>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <IconButton>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {formatDateAndTime(row.createdAt)}
                    </TableCell>
                    <TableCell align="center" className={styles.iconsCell}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <SmallDrawerTooltip
                            TransitionComponent={Grow}
                            placement="left"
                            interactive
                            leaveDelay={200}
                            title="Ver tarea"
                          >
                            <IconButton
                              id={row.id}
                              aria-label="view"
                              onClick={handleView}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </SmallDrawerTooltip>
                          <SmallDrawerTooltip
                            TransitionComponent={Grow}
                            placement="top"
                            interactive
                            leaveDelay={200}
                            title="Editar tarea"
                          >
                            <IconButton
                              id={row.id}
                              onClick={row.editable ? handleEdit : null}
                              disabled={!row.editable}
                            >
                              <EditIcon
                                fontSize="small"
                                style={{ opacity: row.editable ? 1 : 0.5 }}
                              />
                            </IconButton>
                          </SmallDrawerTooltip>
                          <SmallDrawerTooltip title="Asociar">
                            <IconButton
                              id={row.id}
                              aria-label="associate"
                              onClick={handleAssociate}
                            >
                              <Arrow fontSize="small" />
                            </IconButton>
                          </SmallDrawerTooltip>
                          <SmallDrawerTooltip title="Editar asociaciones">
                            <IconButton
                              id={row.id}
                              aria-label="edit-associations"
                              onClick={handleEditAssociations}
                            >
                              <EditTaskIcon fontSize="small" />
                            </IconButton>
                          </SmallDrawerTooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableToolbarActions
              selected={selected}
              setSelected={setSelected}
              rowsToShow={rowsToShow}
              setUpdate={setUpdate}
              setMessage={setMessage}
              setSeverity={setSeverity}
              searchText={searchText}
              setSearchText={setSearchText}
              setColumn={setColumn}
              setFilter={setFilter}
              placeholder={t('task.tableToolbar.filterPlaceholder')}
              onlyDelete
              deleteFunction={handleDeleteSelected}
              table={table}
            />
            <TableRow>
              <TableCell
                className={styles.tableHeader}
                padding="checkbox"
                style={{ width: columnWidths.checkbox }}
              />
              {tableHeaders.map((tableHeader) => (
                <TableCell
                  key={tableHeader.id}
                  className={styles.tableHeader}
                  align={tableHeader.id === 'actions' ? 'center' : 'left'}
                  padding="normal"
                  sortDirection={sortBy === tableHeader.id ? sortOrder : false}
                  style={{ width: columnWidths[tableHeader.id] }}
                >
                  <TableSortLabel
                    active={sortBy === tableHeader.id}
                    direction={sortBy === tableHeader.id ? sortOrder : 'asc'}
                    onClick={() => handleSort(tableHeader.id)}
                  >
                    {tableHeader.id === 'title' && selected.length
                      ? `Selected ${selected.length} tasks`
                      : tableHeader.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={7} className={styles.progress}>
                  <CircularProgress />
                  <h4>{t('task.loading')}</h4>
                </TableCell>
              </TableRow>
            ) : content()}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPage={setPage}
        page={page}
        rowsPerPage={rowsPerPage}
        quantity={surveysQuantity}
      />
    </>
  );
}

export default TasksTable;
