/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  Button, Checkbox, FormControlLabel, TextField, IconButton,
  Tooltip, Grid, List, ListItem, ListItemText,
  Box,
} from '@material-ui/core';

// icons
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import DeleteIcon from '@material-ui/icons/Close';
import Filter2Icon from '@material-ui/icons/Filter2';

import {
  EDIT_MODE, VIEW_MODE,
} from '../../../utils/const/taskModes';

import useStyles from '../styles';
import ExampleImageQuestion from '../../ExampleImageQuestion';
import EmailsBackup from '../../EmailsBackup';

function SimpleSelection({
  identifier, pages, info, setUpdate, handleDeleteQuestion,
  mode, handleDuplicateQuestion, // formType,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const initialId = 1;
  const [correct, setCorrect] = useState(['']);

  const [open, setOpen] = useState(false);
  const [openEmails, setOpenEmails] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailsArray, setEmailsArray] = useState([]);
  const [file, setFile] = useState({});
  const [image, setImage] = useState({});

  // const [options, setOptions] = useState(info ? info.QuestionChoices
  //   : pages[identifier - 1].questionChoices || []);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (info) {
      setImage({
        ...image,
        url: info.imageUrl,
        name: info.imageKey,
      });
      if (
        info.QuestionEmails !== undefined
        && info.QuestionEmails.length !== 0) {
        setEmailsArray(info.QuestionEmails[0].emails.split(','));
      }
      setSendEmail(info.sendEmail);
      if (info.CorrectQuestionChoices[0] && info.CorrectQuestionChoices[0].questionChoiceId) {
        setCorrect(Number.parseInt(info.CorrectQuestionChoices[0].questionChoiceId, 10));
      }
      setOptions(info.QuestionChoices);
    } else if (pages && pages[identifier - 1].questionChoices) {
      const { questionChoices } = pages[identifier - 1];

      const optionsArray = [];
      let identifierChoice = 0;
      questionChoices.forEach((questionChoice) => {
        identifierChoice += 1;
        optionsArray.push({
          title: questionChoice.title,
          type: questionChoice.type,
          identifier: identifierChoice,
          // identifier: `${identifierChoice}`,
          id: questionChoice.id,
        });
      });
      if (pages[identifier - 1].correctQuestionChoices) {
        // eslint-disable-next-line max-len
        const correctQuestionChoiceId = pages[identifier - 1].correctQuestionChoices[0].questionChoiceId;
        const correctIndex = optionsArray.findIndex(
          (questionChoice) => questionChoice.id === correctQuestionChoiceId,
        );
        setCorrect(Number.parseInt(correctIndex + 1, 10));
        pages[identifier - 1] = {
          ...pages[identifier - 1],
          questionAnswer: [Number.parseInt(correctIndex + 1, 10)],
        };
      }
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionChoices: optionsArray,
        questionAnswer: correct,
      };
      setOptions(optionsArray);
      if (
        pages[identifier - 1].questionEmails !== undefined
        && pages[identifier - 1].questionEmails.length !== 0) {
        setEmailsArray(pages[identifier - 1].questionEmails[0].emails.split(','));
      }
      setSendEmail(pages[identifier - 1].sendEmail);
    } else {
      setOptions([]);
    }

    if (pages && pages[identifier - 1].imageUrl) {
      setImage({
        ...image,
        url: pages[identifier - 1].imageUrl,
        name: pages[identifier - 1].imageKey,
      });
    }
  }, [pages]);

  const handleAddOption = () => {
    if (options.length && !options[options.length - 1].title) return;

    const generatedId = initialId + options.length;
    setOptions([
      ...options, {
        identifier: `${generatedId}`,
        title: '',
        type: 'text',
      }]);
  };

  const handleOnChange = (event) => {
    const { id, value, imageUrl } = event.target;

    setUpdate((prevState) => !prevState);

    if (id === 'title' || id === 'description' || id === 'imageKey'
      || id === 'sendEmail' || id === 'questionEmails') {
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        [id]: value,
        questionLogic: [{
          questionIdentifier: identifier,
          questionChoiceIdentifier: null,
          nextQuestionIdentifier: `${Number.parseInt(identifier, 10) + 1}`,
        }],
        questionAnswer: correct,
        imageUrl,
      };
    } else {
      const indexOption = options.findIndex(
        (option) => `${option.identifier}` === id,
      );

      if (indexOption !== -1) options[indexOption].title = value;
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionChoices: options,
        questionAnswer: correct,
      };
    }
  };

  const handleCorrectAnswer = (event, identifierid) => {
    if (event.target.checked) {
      setCorrect([identifierid]);
      pages[identifier - 1] = {
        ...pages[identifier - 1],
        questionAnswer: [identifierid],
      };
    }
  };

  const isCorrect = (identifierid) => {
    if (!correct) return false;
    if (Number.parseInt(correct, 10) === Number.parseInt(identifierid, 10)) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p className={styles.identifier}>{identifier}.</p>
        <div className={styles.questionContainer}>
          <TextField
            id="title"
            className={styles.question}
            placeholder={t(
              'task.taskEdition.simpleSelectionText.question',
            )}
            value={info ? info.title : pages[identifier - 1].title}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 70 }}
            multiline
            disabled={mode === VIEW_MODE}
          />
          <TextField
            id="description"
            className={styles.description}
            placeholder={t(
              'task.taskEdition.simpleSelectionText.description',
            )}
            value={info ? info.description : pages[identifier - 1].description}
            onChange={mode === EDIT_MODE ? handleOnChange : null}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 140 }}
            multiline
            disabled={mode === VIEW_MODE}
          />
          {options.map((option) => (
            <div key={option.identifier || `${option.id}`}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={styles.checkbox}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon style={{ color: 'green' }} />}
                    name={option.identifier}
                    onClick={(event) => handleCorrectAnswer(event, option.identifier)}
                    checked={isCorrect(option.identifier || `${option.id}`)}
                    disabled={mode === VIEW_MODE}
                  />
                }
                label={(
                  <TextField
                    id={option.identifier}
                    className={styles.option}
                    value={option.title}
                    placeholder={`${t('task.taskEdition.simpleSelectionText.write')} ${t('task.taskEdition.simpleSelectionText.option')}`}
                    InputProps={{ disableUnderline: true }}
                    onChange={mode === EDIT_MODE ? handleOnChange : null}
                    disabled={mode === VIEW_MODE}
                    multiline
                    maxRows={5}
                    fullWidth
                  />
                )}
              />
            </div>

          ))}
          <Button
            onClick={handleAddOption}
            className={clsx({
              [styles.hide]: info,
            })}
          >
            {t('task.taskEdition.simpleSelectionText.addOption')}
          </Button>
          {mode !== VIEW_MODE ? (
            <>
              <Button
                onClick={() => setOpen(true)}
              >
                {t('task.taskForm.addMediaTitle')}
              </Button>
              <Button
                onClick={() => setOpenEmails(true)}
              >
                {t('task.taskForm.addEmails')}
              </Button>
            </>
          ) : null
          }
          {image.url
            ? <>
              <Grid container>
                <Grid item xs={1}>
                  <Tooltip
                    title={
                      <img
                        alt='reference-image'
                        src={image.url}
                        width='100%'
                        height='100%'
                      />
                    }
                    arrow
                    placement='top-end'
                  >
                    <img
                      alt='reference-image'
                      src={image.url}
                      width='50px'
                      height='50px'
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={5}>
                  <List className={styles.list}>
                    <ListItem>
                      <ListItemText
                        primary={image.name}
                        primaryTypographyProps={{ variant: 'h5' }}
                        secondary={image.size ? `  (${image.size})` : null}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </>
            : null
          }
        </div>
      </div>
      {mode !== VIEW_MODE ? (
        <Box>
          <Tooltip title={t('task.taskEdition.duplicate')}>
            <IconButton
              id={identifier}
              onClick={handleDuplicateQuestion}
              className={styles.deleteButton}
            >
              <Filter2Icon />
            </IconButton>
          </Tooltip>
          <IconButton
            id={identifier}
            onClick={handleDeleteQuestion}
            className={styles.deleteButton}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : null}
      <ExampleImageQuestion
        open={open}
        setOpen={setOpen}
        file={file}
        setFile={setFile}
        handleOnChange={handleOnChange}
        setImage={setImage}
      />
      <EmailsBackup
        sendEmail={sendEmail}
        setSendEmails={setSendEmail}
        open={openEmails}
        setOpen={setOpenEmails}
        emailsArray={emailsArray}
        setEmailsArray={setEmailsArray}
        handleOnChange={handleOnChange}
        d disabled={!!info}
      />
    </div>
  );
}

export default SimpleSelection;
